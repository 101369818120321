// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentConfigBase } from "src/app/models/app.model";

export const environment: EnvironmentConfigBase = {
  production: false,
  baseUrl: 'https://api.cstc.terrabo.org',
  businessDateShift: 6,
  kiosk: false,
  ymId: 92869871,
  docs: {
    offer: 'dogovor-oferta',
    rules: 'uslovia-pokupki-elektronnogo-bileta',
  },
  pwa: {
    checkInterval: 5 * 60 * 1000, // 5 minutes
    forceUpdateDelay: 3000, // 3 seconds before reload
    swRegistrationStrategy: 'registerWhenStable:30000',
    minSpinnerDuration: 5000
  },
  defaultCity: {
    id: 1,
    name: 'Москва и МО',
    geo: {
      lat: 55.7743,
      lng: 37.6299,
    },
  },
  platform: 'web',
  store: 'default',
  stream: {
    desktop: 1086936719,
    mobile: 1754078572
  },
  timers: {
    delayCountdown: 30,
    countdownDuration: 30,
    updateSchedule: 60
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
