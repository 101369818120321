import { ChangeDetectionStrategy, Component, inject, OnInit, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SwUpdate } from '@angular/service-worker';
import { interval, Subscription } from 'rxjs';
import { ButtonComponent } from 'src/app/modules/shared/components/button/button.component';
import { LoaderComponent } from 'src/app/modules/shared/components/loader/loader.component';

@Component({
  selector: 'cstc-update-screen',
  standalone: true,
  imports: [
    // ButtonComponent,
    LoaderComponent,
  ],
  templateUrl: './update-screen.component.html',
  styleUrl: './update-screen.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateScreenComponent implements OnInit {
  swUpdate = inject(SwUpdate);

  showLoader = signal(false);
  progress = signal(0);
  estimatedTime = signal(30);
  private timerSubscription: Subscription | null = null;

  ngOnInit(): void {
    this.startCountdown();

    // if ('serviceWorker' in navigator) {
    //   navigator.serviceWorker.addEventListener('message', (event) => {
    //     console.log('[APP] Получено сообщение от SW:', event.data);
    //     if (event.data.type === 'SW_PROGRESS') {
    //       this.progress.set(event.data.progress);
    //     }
    //   });
    // }
  }

  startCountdown() {
    this.estimatedTime.set(30);
    this.timerSubscription = interval(1000).subscribe(() => {
      const timeLeft = this.estimatedTime() - 1;
      this.estimatedTime.set(timeLeft);
      if (timeLeft <= 0 || this.showLoader() === true) {
        this.startLoading();
      }
    });
  }

  startLoading() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.showLoader.set(true);
    this.progress.set(0);
    this.estimatedTime.set(30);
  }

  completeUpdate() {
    this.swUpdate.activateUpdate().then(() => {
      document.location.reload();
    });
    // console.error('upd complete');
  }
}
