import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, effect, inject, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { IdleService } from 'src/app/services/idle.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { OrderInfoData } from 'src/app/services/order/order.info.model';
import { environment } from 'src/environments/environment';
import { KinozoomLogoComponent } from '../kinozoom-logo/kinozoom-logo.component';
import { NeonPulseDirective } from '../../directives/neon-pulse.directive';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'cstc-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
  ]
})
export class CountdownTimerComponent implements OnInit, OnDestroy, AfterViewInit {
  cdr = inject(ChangeDetectorRef);
  idleSvc = inject(IdleService);
  router = inject(Router);

  durationInSeconds = environment.timers.countdownDuration;  // Время обратного отсчета в секундах
  remainingTime = signal(this.durationInSeconds);
  private subscription!: Subscription;

  // Общее количество блоков, которое равно количеству секунд таймера и берем из настроек
  totalBlocks = this.durationInSeconds;
  // Массив в котором мы получаем количество блоков
  blocks = Array.from({ length: this.totalBlocks });
  // Индекс текущего блока
  currentBlock = 0;

  // Получаем значение маршрута для выбранного при старте кинотеатра
  selectedCinemaUid = this.idleSvc.getSelectedCinemaUid();

  // Проверяум показывать или нет Screensaver, который должен отображаться только на странице кинотеатра
  showScreensaver = computed(() => {
    if (this.router.url.includes('cinema')) {
      return true;
    }
    return false;
  });

  ngOnInit(): void {
    if (!this.showScreensaver()) {
      this.startCountdown();
    }
  }

  ngAfterViewInit(): void {
    this.initClasses();
  }

  ngOnDestroy(): void {
    this.stopCountdown();
  }

  calculateStyle(index: number) {
    // Смещение угла для начала с -90 градусов
    const angleOffset = -90;
    // Расчет угла с учетом смещения
    const angle = (index / this.totalBlocks) * 360 + angleOffset;
    // Радиус круга
    const radius = 180;

    return {
      position: 'absolute',
      transform: `rotate(${angle}deg) translate(${radius}px)`
    }
  }

  // Добавляем класс для последних блоков, чтобы выделить их другим цветом
  initClasses() {
    const blocks = Array.from(
      document.querySelectorAll('.countdown-sec')).slice(
        // Устанивливаем промежуток последних секунд от 0 до 10 (или если блоков < 10, то по их количеству)
        0, Math.min(10, this.totalBlocks));
    blocks.forEach(block => {
      block.classList.add('hurry-up');
    });
  }

  startCountdown() {
    // Обнуляем текущее состояние, если таймер запускается повторно
    this.stopCountdown();
    this.remainingTime.set(this.durationInSeconds);
    // Устанавливаем индекс последнего блока
    this.currentBlock = this.blocks.length - 1;

    this.subscription = interval(1000).subscribe(() => {
      if (this.remainingTime() > 0) {
        this.remainingTime.update(value => value - 1);

        // Снимаем класс 'active' с текущего блока
        const blocks = document.querySelectorAll('.countdown-sec');
        if (blocks[this.currentBlock]) {
          blocks[this.currentBlock].classList.remove('active');

          // Если блок имеет класс 'hurry-up', удаляем его
          if (blocks[this.currentBlock].classList.contains('hurry-up')) {
            blocks[this.currentBlock].classList.remove('hurry-up');
          }
        }

        // Переходим к следующему блоку
        this.currentBlock--;

        this.cdr.markForCheck();
      } else {
        this.stopCountdown();
        // Выполняем редирект после окончания таймера
        this.redirectToHome();
      }
    });
  }

  stopCountdown() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // Очищает текущий таймер бездействия, скрывает компонент и перезапускает слежение за активностью
  resetIdleTimer(event?: MouseEvent) {
    if (event) {
      event.preventDefault();
    }
    this.idleSvc.resetTimer();
  }

  redirectToHome(event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
    }

    this.idleSvc.resetState();

    // Clean redirect to cinema home with auxiliary outlets (like cart) closed
    this.router.navigate(
      [{ outlets: { primary: ['cinema', this.selectedCinemaUid], cart: null } }]
    );
  }
}
