import { DeviceMetadata } from "./state.store";

export function generateEnhancedUUID() {
  return generateUUID(); // Standard UUID v4
}

// Function to generate a standard UUID v4
function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
// Helper function to collect device metadata
export function collectDeviceMetadata(): DeviceMetadata {
  return {
    userAgent: navigator.userAgent,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    colorDepth: window.screen.colorDepth,
    deviceMemory: (navigator as any).deviceMemory || undefined, // Device memory in GB (if supported)
    hardwareConcurrency: navigator.hardwareConcurrency || undefined, // Number of logical processors
    language: navigator.language || 'unknown',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'unknown', // Time zone
  };
}
// Device fingerprint function
function getDeviceFingerprint() {
  return btoa(
    [
      navigator.userAgent, // Browser and OS details
      navigator.language,  // Language settings
      screen.width,        // Screen width
      screen.height,       // Screen height
      new Date().getTimezoneOffset(), // Timezone
    ].join("-")
  );
}

// Simple hashing function (SHA-256 can also be used)
function hashString(input:string) {
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32-bit integer
  }
  return hash.toString(16);
}
