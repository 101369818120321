import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from "@ngrx/signals";
import { computed, inject } from '@angular/core';
import { NavigationEnd, NavigationStart, PRIMARY_OUTLET, Router, UrlSegmentGroup, UrlTree } from "@angular/router";
import { debounceTime, distinctUntilChanged, filter, fromEvent, map, merge, pipe, startWith, tap } from "rxjs";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { withDevtools } from "@angular-architects/ngrx-toolkit";
import { DOCUMENT } from "@angular/common";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { DeviceDetectorService } from 'ngx-device-detector';
import { BreakpointObserver } from "@angular/cdk/layout";
import { generateEnhancedUUID } from "./uuid.helper";
import { ENVIRONMENT_CONFIG } from "../tokens/environment-config.token";
import { environment } from "src/environments/environment";
import { ScheduleType } from "../services/schedule/schedule.model";
import { StorageService } from "../services/storage/storage.service";
// Define the media query for wide screens
const WIDE_SCREEN_SIZE = 1024;
const SCHEDULE_TYPE_KEY = 'store::schedule::type'
export const DEVICE_UUID_KEY = 'device_uuid';

export const SECONDARY_OUTLET = 'cart';
export type OutletStatusType = 'idle' | 'pending';
export type SidebarStatusType = 'opened' | 'closed';
export interface CsctBaseState {
  status: {
    [SECONDARY_OUTLET]:OutletStatusType;
    [PRIMARY_OUTLET]:OutletStatusType;
  };
  isMobileDevice: boolean,
  isStandalone: boolean,
  windowWidth: number|null,
  rightSidebar: SidebarStatusType;
  deviceMetadata: DeviceMetadata | null;
  deviceUuid: string|null;
  online: boolean;
  isPwaInstalled: boolean;
  version?: string;
  isKiosk: boolean;
}
// Helper function to collect device metadata
function collectDeviceMetadata(): DeviceMetadata {
  return {
    userAgent: navigator.userAgent,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    colorDepth: window.screen.colorDepth,
    deviceMemory: (navigator as any).deviceMemory || undefined, // Device memory in GB (if supported)
    hardwareConcurrency: navigator.hardwareConcurrency || undefined, // Number of logical processors
    language: navigator.language || 'unknown',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'unknown', // Time zone
  };
}
export interface DeviceMetadata {
  userAgent: string;
  screenWidth: number;
  screenHeight: number;
  colorDepth: number;
  deviceMemory?: number;
  hardwareConcurrency?: number;
  language: string;
  timeZone: string;
}
export function checkPWAinstalled() {
  return window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone === true;
}
// Helper function to check standalone mode
export function checkStandaloneMode(): boolean {
  // iOS-specific standalone detection
  const isIosStandalone = (window.navigator as any).standalone === true;

  // Generic browser support for standalone detection
  const isBrowserStandalone = window.matchMedia('(display-mode: standalone)').matches;

  // Fallback for query parameters or custom logic (e.g., standalone=true)
  const isCustomStandalone = window.location.search.includes('standalone=true');

  // Return true if any of the above conditions are true
  return isIosStandalone || isBrowserStandalone || isCustomStandalone;
}
const initialState: CsctBaseState = {
  status: {
    [SECONDARY_OUTLET]: 'idle',
    [PRIMARY_OUTLET]: 'idle'
  },
  isMobileDevice: false,
  isStandalone: checkStandaloneMode(),
  windowWidth: window.innerWidth,
  rightSidebar: 'closed',
  deviceMetadata: null,
  deviceUuid: null,
  online: navigator.onLine,
  isPwaInstalled: checkPWAinstalled(),
  isKiosk: false
};
export const CstcStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withDevtools('state'),
  withMethods((store, router = inject(Router), document = inject(DOCUMENT),storageSvc = inject(StorageService))=> ({
    changeOutletStatus(outlet: string, status: OutletStatusType): void {
      patchState(store, state => ({
        status: {
          ...state.status,
          [outlet]: status
        }
      }));
    },
    setScheduleType(type:ScheduleType) {
      storageSvc.setItem(SCHEDULE_TYPE_KEY,type);
    },
    getScheduleType(): ScheduleType {
      const stored = storageSvc.getItem(SCHEDULE_TYPE_KEY);
      if (stored === 'film' || stored === 'theatre') {
        return stored;
      }
      return 'theatre';
    },
    updateRightSidebarState(state: SidebarStatusType): void {
      patchState(store, {
        rightSidebar: state
      });
    },
    checkNavigationStart(event: NavigationStart):void {
      const urlTree: UrlTree = router.parseUrl(event.url);
      const segmentGroup: UrlSegmentGroup = urlTree.root.children[PRIMARY_OUTLET] || urlTree.root.children[SECONDARY_OUTLET];
      if (segmentGroup) {
        const primaryOutletSegments = segmentGroup.segments.map(segment => segment.path);
        if (urlTree.root.children[SECONDARY_OUTLET]) {
          this.changeOutletStatus(SECONDARY_OUTLET,'pending');
          this.updateRightSidebarState('opened');
        } else {
          this.changeOutletStatus(PRIMARY_OUTLET,'pending');
          this.updateRightSidebarState('closed');
        }
      }
    },
    checkNavigationEnd(event: NavigationEnd): void {
      const urlTree: UrlTree = router.parseUrl(event.urlAfterRedirects);
      const segmentGroup: UrlSegmentGroup = urlTree.root.children[PRIMARY_OUTLET] || urlTree.root.children[SECONDARY_OUTLET];
      if (segmentGroup) {
        if (urlTree.root.children[SECONDARY_OUTLET]) {
          this.changeOutletStatus(SECONDARY_OUTLET, 'idle');
        } else {
          this.changeOutletStatus(PRIMARY_OUTLET, 'idle');
        }
      }
    },
    closeSecondaryOutlet() {
      router.navigate([{outlets:{[SECONDARY_OUTLET]:null}}]);
    },
    setInitialData(isMobileDevice: boolean, deviceMetadata: DeviceMetadata,deviceUuid:string, version: string,isKiosk:boolean): void {
      patchState(store, { isMobileDevice, deviceMetadata, deviceUuid, version, isKiosk });
    },
  })),
  withMethods((store)=>({
    handleScreenSize: rxMethod<Event>(
      pipe(
        debounceTime(200),
        takeUntilDestroyed(),
        map(() => {
          const newWidth = window.innerWidth;
          return store.windowWidth() !== newWidth ? newWidth : null; // Only pass updated width
        }),
        filter((value): value is number => value !== null), // Filter out unchanged values
        tap((value)=>patchState(store,{windowWidth:value}))
      )
    ),
    handleRouterEvents: rxMethod<NavigationStart | NavigationEnd>(
      pipe(
        takeUntilDestroyed(),
        tap((event)=>{
          if (event instanceof NavigationStart) {
            store.checkNavigationStart(event);
          } else if (event instanceof NavigationEnd) {
            store.checkNavigationEnd(event);
          }
        })
      )
    ),
    handleOnlineStatus: rxMethod<boolean>(
      pipe(
        takeUntilDestroyed(),
        distinctUntilChanged(),
        tap((status)=>{
          const currentStatus = store.online();
          if(currentStatus!==status) {
            patchState(store,{
              online: status
            })
          }
        })
      )
    )
  })),
  withComputed(({rightSidebar,status,windowWidth})=>({
    rightSidebarOpened: computed(()=>rightSidebar()==='opened'||status()[SECONDARY_OUTLET]==='pending'),
    showLoading: computed(()=>status()[PRIMARY_OUTLET]==='pending'),
    isWideScreen: computed(() => {
      const width = windowWidth();
      return width !== null && width !== undefined && width >= WIDE_SCREEN_SIZE;
    })
  })),
  withHooks((store, router = inject(Router), deviceService = inject(DeviceDetectorService), breakpointObserver = inject(BreakpointObserver), appConfig = inject(ENVIRONMENT_CONFIG))=>{
    let resizeSubscription: any;
    return {
      onInit() {
        const isKiosk = appConfig.kiosk;
        let uuid = localStorage.getItem(DEVICE_UUID_KEY);
        if (!uuid) {
          uuid = generateEnhancedUUID();
          localStorage.setItem(DEVICE_UUID_KEY, uuid);
        }
        store.handleScreenSize(fromEvent(window,'resize'));
        store.handleRouterEvents(router.events.pipe(
          filter((event): event is NavigationStart | NavigationEnd =>
            event instanceof NavigationStart || event instanceof NavigationEnd),
        ));
        const online$ = fromEvent(window, 'online').pipe(map(() => true));
        const offline$ = fromEvent(window, 'offline').pipe(map(() => false));
        // Combine both observables to track online status
        const onlineStatus$ = merge(online$, offline$).pipe(
          startWith(navigator.onLine) // Start with the current online status
        );
        store.handleOnlineStatus(onlineStatus$);
        store.setInitialData(!deviceService.isDesktop(),collectDeviceMetadata(),uuid,appConfig.version,isKiosk)
        patchState(store,{})
      }
    }
  })
);
