import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlatformService } from './platform/platform.service';
@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  tracking$ = new BehaviorSubject(false);
  constructor(private platformService: PlatformService) {
  }
  public async initTracking() {
    console.log('init tracking')
    if (this.platformService.platform() === 'ios') {
      const idfa = cordova.plugins.idfa;
      const granted = idfa.TRACKING_PERMISSION_AUTHORIZED;
      const permission = await idfa.requestPermission();

      if (permission===granted) {
        this.loadMetrikaModule();
      } else {
        // Permission denied, handle this case accordingly.
      }
    } else {
      // For non-Cordova platforms or non-iOS platforms, you can handle tracking differently.
      this.loadMetrikaModule();
    }
  }
  importMetrikaModule() {
    this.initTracking();
  }
  private async requestTracking(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // Define the TrackingRequestInfo object
      const info = {
        primaryColor: '#FF0000',
        secondaryColor: '#00FF00',
        onPrimaryColor: '#0000FF',
        onSecondaryColor: '#FFFF00',
        title: 'Tracking Permission Request',
        text: 'We need your permission to track your data for analytics.',
        subText: 'Help us improve your experience.',
        buttonTitle: 'Allow Tracking',
        reasons: [
          {
            text: 'Analytics',
            image: 'analytics_image_base64',
            tintImage: true,
          },
          {
            text: 'Performance Optimization',
            image: 'performance_image_base64',
            tintImage: true,
          },
        ],
      };

      window.plugins.impacTracking.requestTracking(JSON.stringify(info), (result) => {
        resolve(result);
      }, (error) => {
        resolve(false);
      });
    });
  }

  private loadMetrikaModule() {
    // Dynamically load and configure the MetrikaModule.
    import('ng-yandex-metrika').then((module) => {
      module.MetrikaModule.forRoot([
        {
          id: environment.ymId,
          webvisor: true,
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          ecommerce: 'dataLayer',
        }
      ]);
      this.tracking$.next(true);
    }).catch((error) => {
      console.error('Error importing MetrikaModule: ' + error);
    });
  }
}
