<!-- @if (!showLoader()) {
    <div class="countdown">
        <h3>Загружено обновление</h3>
        <div class="timer-holder">
            <span>Установка начнется автоматически через</span>
            <span class="timer">{{estimatedTime()<10?'0'+estimatedTime():estimatedTime()}}</span>
        </div>
        <div class="btn-holder">
            <span>или</span>
            <a href="#" cstc-button="secondary" class="btn" (click)="showLoader.set(true)">установить</a>
        </div>
    </div>
}@else { -->
    <div class="progress-wrapper">
        <div class="progress-container">
            <!-- <div class="progress-bar" [style.width.%]="progress()"></div> -->
             <cstc-loader class="inline"/>
        </div>
        <div class="loading-text">
            <div class="timer-holder">
                <span>Установка обновлений</span>
                <!-- <span>Установка обновлений, осталось </span> -->
                <!-- <span class="timer">{{estimatedTime()<10?'0'+estimatedTime():estimatedTime()}}</span> -->
            </div>
        </div>
    </div>
<!-- } -->
