import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'cstc-network-error',
  standalone: true,
  imports: [],
  templateUrl: './network-error.component.html',
  styleUrl: './network-error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkErrorComponent { }
