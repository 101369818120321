import { CommonModule, isPlatformBrowser, Location } from '@angular/common';
import { Component, DestroyRef, inject, Inject, OnDestroy, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { Metrika } from 'ng-yandex-metrika';
import { BehaviorSubject, combineLatestWith, filter, Subject, Subscription, take, takeUntil, takeWhile, withLatestFrom } from 'rxjs';
import { CityService } from './services/city/city.service';
import { TrackingService } from './services/tracking.service';
import { IdleService } from './services/idle.service';
import { PlatformService } from './services/platform/platform.service';
import { CountdownTimerComponent } from './modules/shared/components/countdown-timer/countdown-timer.component';
import { CstcStore } from './store/state.store';
import { NetworkErrorComponent } from './components/network-error/network-error.component';
import { UpdateScreenComponent } from './components/update-screen/update-screen.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SwUpdate } from '@angular/service-worker';
import { UpdateService } from './services/update.service';

@Component({
  selector: 'cstc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    CountdownTimerComponent,
    NetworkErrorComponent,
    UpdateScreenComponent,
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Синема Стар';

  /** inject - start */
  private state = inject(CstcStore);
  private metrika = inject(Metrika);
  idleSvc = inject(IdleService);
  platformSvc = inject(PlatformService);
  swUpdate = inject(SwUpdate);
  destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private cityService = inject(CityService);
  private location = inject(Location);
  private trackingSvc = inject(TrackingService);
  updateSvc = inject(UpdateService);
  /** inject - end */

  idleSubscription!: Subscription;
  showCountdown = signal(false);  // переменная для отображения компонента таймера
  kiosk = this.state.isKiosk;
  static isBrowser = new BehaviorSubject<boolean | null>(null);
  showBranding = true;
  online = this.state.online;
  updateAvailable = this.updateSvc.isUpdating;

  constructor(
    @Inject(PLATFORM_ID) platformId: any,
  ) {
    this.trackingSvc.initTracking();
    let prevPath = this.location.path();
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
    const event$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    );
    this.trackingSvc.tracking$.pipe(
      takeUntilDestroyed(),
      filter(value => value === true),
      take(1),
    ).subscribe(() => {

    })
    event$.pipe(
      combineLatestWith(this.trackingSvc.tracking$),
      takeUntilDestroyed(),
    ).subscribe(
      ([, tracking]) => {
        const newPath = this.location.path();
        if (tracking) {
          this.metrika.hit(newPath, {
            referer: prevPath,
          });
        }
        prevPath = newPath;
        this.showBranding = (this.route.snapshot.firstChild?.data['hideBranding']) ? false : true;
      });

    /** app update */
    // if (this.swUpdate.isEnabled) {
    //   this.swUpdate.versionUpdates.pipe(
    //     takeUntilDestroyed()
    //   ).subscribe((event) => {
    //     if (event.type === 'VERSION_READY') {
    //       this.updateAvailable.set(true);
    //     }
    //   })
    // }
  }

  ngOnInit(): void {
    this.cityService.initCityList();
    if (this.kiosk()) {
      this.idleSvc.startWatching();
    }
    this.idleSubscription = this.idleSvc.idleState$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((state) => {
      if (this.kiosk()) {
        if (this.router.url !== '/kiosk') {
          this.showCountdown.set(state);
        }
      }
    });
  }
  ngOnDestroy(): void {
    this.idleSvc.stoptWatching();
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }
}

