import { PaymentMethod } from "./session.model";

export const PLATFORM_KIOSK = 'kiosk';
export const PLATFORM_WEB = 'web';
export const PLATFORM_IOS = 'ios';
export const PLATFORM_ANDROID = 'android';


// Aggregate platforms into an array
export const AVAILABLE_PLATFORMS = [
  PLATFORM_KIOSK,
  PLATFORM_WEB,
  PLATFORM_IOS,
  PLATFORM_ANDROID,
] as const;
export const KIOSK_LOCAL_STORAGE_LOCATION_KEY = 'kioskLocation';
// Create a union type from the constants
export type AppPlatform = typeof AVAILABLE_PLATFORMS[number];
export interface GeoCords {
  lat: number;
  lng: number;
}
export type AppStoreType = 'default' | 'ru';
export type StreamConfig = {
  standalone?: Omit<StreamConfig, 'standalone'>; // Reuse the same structure but exclude `standalone`
} & (
  | { default: number } // Case 1: `default` is present
  | { desktop: number; mobile: number } // Case 2: Both `desktop` and `mobile` are required
);
export interface FilmFilters {
  sale: number[]
}
export interface PwaConfig {
  checkInterval: number; // How often to check for updates (ms)
  forceUpdateDelay: number; // Delay before forcing reload (ms)
  swRegistrationStrategy: string; // Service Worker registration strategy
  minSpinnerDuration: number;
}
export interface EnvironmentConfigBase {
  production: boolean;
  platform: AppPlatform;
  kiosk: boolean;
  ymId: number;
  baseUrl: string;
  businessDateShift: number;
  filters?: FilmFilters,
  pwa?: PwaConfig,
  docs: {
    offer: string;
    rules: string;
  },
  allowedPaymentTypes?: PaymentMethod[],
  defaultCity: {
    id: number;
    name: string;
    geo: GeoCords;
  },
  store: AppStoreType;
  version?: string;
  stream: StreamConfig,
  timers: {
    delayCountdown: number;
    countdownDuration: number;
    updateSchedule: number;
  }
}
// Final resolved config with version required
export interface EnvironmentConfigResolved extends EnvironmentConfigBase {
  version: string; // Required after processing
}
