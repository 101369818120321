import { inject, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  ngZone = inject(NgZone);

  // Преременная для хранения маршрута выбранного кинотеатра
  selectedCinemaUid: string | null = null;

  private idleTimeout: any;
  private idleTime = environment.timers.delayCountdown; // Время бездействия

  // состояние для управления бездействием
  idleState$ = new BehaviorSubject<boolean>(false);

  startWatching() {
    this.resetTimeout();
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('mousemove', this.resetTimeout.bind(this));
      window.addEventListener('keydown', this.resetTimeout.bind(this));
      window.addEventListener('click', this.resetTimeout.bind(this));
      window.addEventListener('touchstart', this.resetTimeout.bind(this));
      window.addEventListener('wheel', this.resetTimeout.bind(this));
    });
  }

  stoptWatching() {
    window.removeEventListener('mousemove', this.resetTimeout.bind(this));
    window.removeEventListener('keydown', this.resetTimeout.bind(this));
    window.removeEventListener('click', this.resetTimeout.bind(this));
    window.removeEventListener('touchstart', this.resetTimeout.bind(this));
    window.addEventListener('wheel', this.resetTimeout.bind(this));
    this.clearTimeout();
  }

  resetTimeout() {
    this.clearTimeout();

    let timeoutDuration = this.idleTime;
    if (window.location.href.includes('qr')) {
      timeoutDuration *= 4; //устанавливаем время бездействия на странице qr в 4 раза больше
    }
    // console.log(`Время бездействия перед запуском таймера: ${timeoutDuration} секунд`);

    this.idleTimeout = setTimeout(() => {
      this.ngZone.run(() => {
        this.idleState$.next(true); // обновляем состояние бездействия
      });
    }, timeoutDuration * 1000);
  }

  clearTimeout() {
    if (this.idleTimeout) {
      clearTimeout(this.idleTimeout);
      this.idleTimeout = null;
    }
  }

  resetState() {
    this.idleState$.next(false);  // Сбрасываем состояние на изначальное
  }

  resetTimer() {
    this.clearTimeout();  // Останавливаем текущий таймер
    this.resetState();  // Сбрасываем состояние на изначальное
    this.startWatching(); // Перезапускаем слежение за активностью
  }

  // Методы для установки и получения маршрута для выбранного кинотеатра
  setSelectedCinemaUid(uid: string) {
    this.selectedCinemaUid = uid;
    // Сохраняем в хранилище для пеестраховки, т.к. данные хранятся в сервисе только в течении жизненного цикла сессии
    localStorage.setItem('selectedCinemaUid', uid);
  }
  getSelectedCinemaUid() {
    // Получаем значение из хранилища если оно равно null
    if (!this.selectedCinemaUid) {
      this.selectedCinemaUid = localStorage.getItem('selectedCinemaUid');
    }
    return this.selectedCinemaUid;
  }
}
