import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, Input, HostBinding, Output, EventEmitter, inject } from '@angular/core';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';

@Component({
  selector: 'a[cstc-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class ButtonComponent implements OnInit {
  state = inject(CstcStore);

  @Input() disabled = false;

  @Input('cstc-button') appButton: 'primary' | 'secondary' | 'outline' = 'secondary';

  @HostBinding('class') get hostClass() {
    return {
      'btn-primary': this.appButton === 'primary',
      'btn-secondary': this.appButton === 'secondary',
      'btn-outline': this.appButton === 'outline',
      'kiosk': this.state.isKiosk(),
    }
  }

  //**для перехода по ссылке */
  @Input() url = "";
  @Output() btnClick = new EventEmitter();

  click(event: MouseEvent) {
    if (this.url === "") {
      event.preventDefault();
    }
    this.btnClick.emit(event);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
