@if (online()) {
    @if(updateAvailable()){
        <cstc-update-screen/>
    }@else {
        <router-outlet></router-outlet>
        <router-outlet name="cart" #o="outlet"></router-outlet>
        <cstc-countdown-timer *ngIf="showCountdown()" [class.show]="kiosk()"></cstc-countdown-timer>
    }
}
@else {
    <cstc-network-error/>
}
