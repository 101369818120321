import { Injectable, signal, inject, PLATFORM_ID, Optional } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ENVIRONMENT_CONFIG } from '../tokens/environment-config.token';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UpdateService {
  private platformId = inject(PLATFORM_ID);
  private appConfig = inject(ENVIRONMENT_CONFIG, { optional: true });
  private swUpdate = inject(SwUpdate, { optional: true }); // ✅ Make SwUpdate optional
  isUpdating = signal(false);
  private updateCheckIntervalId: any = null; // ✅ Store interval ID to prevent multiple timers

  private config = this.appConfig?.pwa ?? {
    checkInterval: 0, // No updates if PWA is not configured
    forceUpdateDelay: 3000,
    swRegistrationStrategy: 'registerWhenStable:30000',
    minSpinnerDuration: 2000,
  };

  constructor() {
    if (!isPlatformBrowser(this.platformId) || !this.config.checkInterval || !this.swUpdate?.isEnabled) {
      return; // ✅ Exit safely if SSR, PWA is disabled, or SwUpdate is missing
    }

    this.startUpdateCheck();
  }

  public startUpdateCheck() {
    if (!isPlatformBrowser(this.platformId) || !this.config.checkInterval || !this.swUpdate) return;

    // Subscribe once to handle available updates
    this.swUpdate.versionUpdates.subscribe(event => {
      if (event.type === 'VERSION_READY') {
        console.log('✅ New version detected, updating now...');
        this.forceUpdate();
      }
    });

    // ✅ Immediately check for updates on app start
    this.swUpdate.checkForUpdate().then(hasUpdate => {
      if (hasUpdate) {
        console.log('🔄 Update is available immediately on load.');
      } else {
        console.log('✔️ App is up to date on initial check.');
      }
    }).catch(err => {
      console.error('❌ Error checking for update:', err);
    });

    // ✅ Prevent multiple intervals
    if (!this.updateCheckIntervalId) {
      this.updateCheckIntervalId = setInterval(() => {
        this.swUpdate?.checkForUpdate().catch(err =>
          console.error('❌ Error during scheduled update check:', err)
        );
      }, this.config.checkInterval);
    }
  }

  private async forceUpdate() {
    if (!isPlatformBrowser(this.platformId) || !this.config.forceUpdateDelay) return;

    this.isUpdating.set(true);
    console.log('Showing spinner...');

    const minSpinnerTime = this.config.minSpinnerDuration;
    const reloadTime = this.config.forceUpdateDelay;

    await Promise.all([
      this.delay(minSpinnerTime),
      this.delay(reloadTime),
    ]);

    console.log('Reloading...');
    window.location.reload();
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
