import { HttpClient, HttpHeaders } from '@angular/common/http';
import { effect, inject, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, delay, EMPTY, map, Observable, of, pluck, take } from 'rxjs';
import { CreateOrderRequestResponse, RefundOrderRequestResponse } from 'src/app/models/order.model';
import { Message, PaymentMethod, Session, SessionHall } from 'src/app/models/session.model';
import { SessionPrice, SaleConfig } from '../../models/session.model';
import { ShoppingCartService } from './shopping-cart-service.service';
import { DOCUMENT } from '@angular/common';
import { OrderInfoData, OrderInfoReponse, PaymentRedirectData } from './order.info.model';
import { PlatformService } from '../platform/platform.service';
import { ENVIRONMENT_CONFIG } from 'src/app/tokens/environment-config.token';
import { toSignal } from '@angular/core/rxjs-interop';
import { CstcStore } from 'src/app/store/state.store';
declare var cordova: any;

interface InAppBrowserEvent {
  type: 'loadstart' | 'loadstop' | 'loaderror' | 'message' | 'exit';
  url: string;
  code?: number;
  message?: string;
  data?: string;
}
@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private state = inject(CstcStore);
  private appConfig = inject(ENVIRONMENT_CONFIG);
  private baseUrl = this.appConfig.baseUrl;
  session$ = new BehaviorSubject<Session | undefined>(undefined);
  error$ = new BehaviorSubject('');
  currentStep$ = new BehaviorSubject<number>(1);
  messages$ = new BehaviorSubject<Message[] | undefined>(undefined);
  _currentStep = 1;
  priceList$ = new BehaviorSubject<SessionPrice[] | undefined>(undefined);
  hall$ = new BehaviorSubject<SessionHall | undefined>(undefined);
  cartOpen$ = new BehaviorSubject(false);
  saleConfig$ = new BehaviorSubject<SaleConfig | undefined>(undefined);
  isMobileApp = this.platformSvc.isMobileApp;
  inAppBrowserRef: any;
  cartOpened = toSignal(this.cartOpen$);
  kiosk = this.state.isKiosk;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private httpClient: HttpClient,
    private router: Router,
    private cartSvc: ShoppingCartService,
    private platformSvc: PlatformService
  ) { }

  createOrder(data: any) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<CreateOrderRequestResponse>(
      `${this.baseUrl}/booking/create`,
      data,
      { headers: headers }
    ).pipe(
      map((result: CreateOrderRequestResponse) => {
        if (result.data) {
          return result.data;
        }
        throw new Error(result.message ? result.message : 'Не удалось создать заказ. Попробуйте позже или обратитесь в службу технической поддержки.');
      })
    );
  }
  doRefundOrder(orderId: string, data: {
    contact: string;
  }) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<RefundOrderRequestResponse>(
      `${this.baseUrl}/booking/refund/${orderId}`,
      data,
      { headers: headers }
    );
  }

  setCurrentStep(step: number) {
    if (this._currentStep !== step) {
      this._currentStep = step;
      this.currentStep$.next(this._currentStep);
    }
  }

  setSession(session: Session | undefined) {
    this.session$.next(session);
  }
  setPriceList(priceList: SessionPrice[] | undefined) {
    this.priceList$.next(priceList);
  }
  setSaleConfig(saleConfig: SaleConfig | undefined) {
    // Retrieve allowed payment types from appConfig, defaulting to an empty array if undefined
    const filterForPaymentMethods: PaymentMethod[] = this.appConfig.allowedPaymentTypes || [];

    // If a filter is defined and not an empty array, apply the filter
    if (filterForPaymentMethods.length > 0 && saleConfig) {
      saleConfig.allowed = saleConfig.allowed?.filter(method =>
        filterForPaymentMethods.includes(method)
      ) || [];
    }

    // Update the observable with the (possibly filtered) sale configuration
    this.saleConfig$.next(saleConfig);
  }
  setMessages(messages: Message[] | undefined) {
    this.messages$.next(messages);
  }
  setHall(hallData: SessionHall | undefined) {
    this.hall$.next(hallData);
  }
  cartOpen() {
    this.cartOpen$.next(true);
  }
  cartClose() {
    this.cartOpen$.next(false);
  }
  exitCart() {
    this.clearErrorMessage();
    this.router.navigate(['', { outlets: { cart: null } }]);
  }
  navigateBack() {
    const isKiosk = this.kiosk();
    if(isKiosk) {
      this.exitCart();
    } else {
      if (this._currentStep === 1) {
        this.exitCart();
      } else {
        this.router.navigate(['', { outlets: { cart: ['setup'] } }], { skipLocationChange: true });
      }
    }
  }
  getInfo(id: string): Observable<OrderInfoData> {
    return this.httpClient.get<OrderInfoReponse>(`${this.baseUrl}/booking/info/${id}`).pipe(
      pluck('data')
    );
  }
  cancelOrder(id: string) {
    return of(true);
  }
  emitErrorMessage(message: string) {
    this.error$.next(message);
  }
  clearErrorMessage() {
    this.error$.next('');
  }
  loadStartCallBack(event: InAppBrowserEvent, bookingId: string) {
    if (event.url.includes(`/result/${bookingId}`)) {
      if (this.inAppBrowserRef) {
        this.inAppBrowserRef.close();
      }
      this.router.navigate(['/result', bookingId]).finally(() => {
        this.cartClose();
      });
      // this.inAppBrowserRef.close();
    }
    // event.then((app)=>{
    //   if(app.event.url.includes(`/result/${orderId}`)) {
    //     this.inAppBrowserRef.close();
    //   }
    // });
  }
  onAppBrowserClose(event: any) {
    // this.getOrderInfo(this.orderId);
    // this.spinner.hide('main-spinner');
    console.log('close browser event', event);
  }
  navigateNext() {
    if (this._currentStep === 1) {
      this.router.navigate(['', { outlets: { cart: ['payment'] } }], { skipLocationChange: true });
    } else {
      this.cartSvc.startPending();
      this.clearErrorMessage();
      const data = this.cartSvc.getCartData();
      const paymentData = this.cartSvc.getPaymentData();
      if (data?.session && !paymentData) {
        if (data)
          this.createOrder(data).pipe(
            take(1),
            catchError((error: Error) => {
              console.log(error.message);
              this.emitErrorMessage(error.message);
              this.cartSvc.stopPending();
              return EMPTY;
            }),
          ).subscribe(result => {
            this.cartSvc.stopPending();
            if (result) {
              if (result?.redirect && result?.bookingId) {
                this.cartSvc.setBookingInfo(result.redirect, result.bookingId, result.qr || '');
                this.paymentRedirect({
                  bookingId: result.bookingId,
                  paymentUrl: result.redirect,
                  qr: result.qr || ''
                });
              } else {
                this.emitErrorMessage('Не удалось создать заказ. Попробуйте позже или обратитесь в службу технической поддержки.2');
              }
            } else {
              this.emitErrorMessage('Не удалось создать заказ. Попробуйте позже или обратитесь в службу технической поддержки.1');
            }
          });
      } else {
        if (paymentData) {
          this.paymentRedirect(paymentData);
        }
        this.cartSvc.stopPending();
      }

    }
  }
  paymentRedirect(data: PaymentRedirectData | null) {
    if (data) {
      if (data.qr) {
        console.log(data.qr)
        this.router.navigate([
          {
            outlets: {
              primary: ['qr', data.bookingId], // Main outlet with route and param
              cart: null                     // Closing "cart" outlet
            }
          }
        ]);
      } else {
        if (!this.isMobileApp()) {
          this.document.location.href = data?.paymentUrl;
        } else {
          const config = 'clearcache=yes,clearsessioncache=yes,hidenavigationbuttons=yes,location=no,presentationstyle=pagesheet,hardwareback=no,zoom=no,closebuttoncaption=Готово';
          this.inAppBrowserRef = cordova.InAppBrowser.open(data?.paymentUrl, '_blank', config);
          this.inAppBrowserRef.addEventListener('loadstart', (event: InAppBrowserEvent) => this.loadStartCallBack(event as InAppBrowserEvent, data.bookingId));
          this.inAppBrowserRef.addEventListener('exit', (event: InAppBrowserEvent) => {
            // console.log("EXITTTT",event);
            // if(this.inAppBrowserRef) {
            //   this.inAppBrowserRef.removeEventListener('exit');
            //   this.inAppBrowserRef.removeEventListener('loadstart');
            // }
            this.inAppBrowserRef = undefined;
          })
          // this.router.navigate(['/result',data.bookingId]).finally(()=>{

          // });
        }
      }
    }
  }
  clear() {
    this.session$.next(undefined);
    this.priceList$.next(undefined);
    this.hall$.next(undefined);
    this.saleConfig$.next(undefined);
  }
}
