import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, inject, Injectable, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[iconName]',
  standalone: true,
})
@Injectable({ providedIn: 'root' })
export class SvgIconDirective implements OnInit, OnDestroy {
  element = inject(ElementRef);
  renderer = inject(Renderer2);
  http = inject(HttpClient);

  @Input('iconName') iconName!: string;
  private subscription!: Subscription;

  ngOnInit(): void {
    if (!this.iconName) {
      console.warn('Icon name is not provided for SvgIconDirective');
      return;
    }

    if (this.iconName) {
      const svgPath = `assets/svg/${this.iconName}.svg`;
      this.subscription = this.http.get(svgPath, { responseType: 'text' }).subscribe({
        next: (data) => this.insertSvgContent(data),
        error: (error) => console.error(`Error loading SVG ${this.iconName}:`, error)
      })
    };
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private insertSvgContent(svgContent: string): void {
    this.renderer.setProperty(this.element.nativeElement, 'innerHTML', svgContent);
  }
}
