import { inject, Injectable, signal } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { ShoppingCart } from '../../models/order.model';
import { BehaviorSubject } from 'rxjs';
import {
  HallLevel,
  HallPlace,
  Session,
  SessionPrice,
} from 'src/app/models/session.model';
import { SaleConfig, Ticket } from '../../models/session.model';
import { CookieService } from '../cookie/cookie.service';
import { PaymentRedirectData } from './order.info.model';
import { HashService } from '../hash/hash.service';
import { PlatformService } from '../platform/platform.service';
import { CstcStore } from 'src/app/store/state.store';

const CART_KEY = 'cart';

@Injectable({
  providedIn: 'root',
})
export class ShoppingCartService {
  platformSvc = inject(PlatformService);
  private state = inject(CstcStore);

  kiosk = this.state.isKiosk;
  maxTickets = 6;
  showNotification = signal(false);
  cart$!: BehaviorSubject<ShoppingCart>;
  ready$ = new BehaviorSubject(false);
  public pending$ = new BehaviorSubject(false);
  // public ready$ = new BehaviorSubject(false);
  private pending = false;
  cart!: ShoppingCart;
  saveAttributes = [
    'session',
    'price',
    'tickets',
    'products',
    'params',
    'createdAt',
    'updatedAt',
  ];
  constructor(private storage: StorageService, private cookie: CookieService, private hashSvc: HashService) {
    this.init();
  }
  private init() {
    const cart = new ShoppingCart(this.cookie, this.storage);
    const storedCart = this.storage.getItem(CART_KEY);
    if (storedCart) {
      cart.updateFrom(JSON.parse(storedCart));
    }
    this.cart = cart;
    this.dispatch(true);
  }
  startPending() {
    if (this.pending !== true) {
      this.pending = true;
      this.pending$.next(this.pending);
    }
  }
  stopPending() {
    if (this.pending !== false) {
      this.pending = false;
      this.pending$.next(this.pending);
    }
  }
  getPaymentData(): PaymentRedirectData | null {
    const data = { ...this.cart };
    if (data.bookingId && data.paymentUrl) {
      return {
        bookingId: data.bookingId,
        paymentUrl: data.paymentUrl,
        qr: data.qr || ''
      };
    }
    return null;
  }
  getCartData() {
    const data = { ...this.cart };
    const form = this.cart.contacts;
    if (form && !form?.valid) {
      form.get('email')?.markAsTouched();
      form.get('phone')?.markAsTouched();
      form.get('buyer.first_name')?.markAsTouched();
      form.get('buyer.middle_name')?.markAsTouched();
      form.get('buyer.last_name')?.markAsTouched();
      form.get('offer')?.markAsTouched();
      form.get('payment')?.markAsTouched();
      return;
    }
    const contacts = {
      email: form?.get('email')?.value,
      phone: form?.get('phone')?.value,
      offer: form?.get('offer')?.value,
      payment: form?.get('payment')?.value,
      buyer: form?.get('buyer')?.value
    };

    return {
      contacts: contacts,
      mode: data.mode,
      session: data.session?.id,
      products: data.products,
      tickets: data.tickets,
    };
  }
  setSession(session: Session, price: SessionPrice[], params: SaleConfig) {
    this.clear();
    this.cart.session = session;
    this.cart.price = price;
    this.cart.params = params;
    this.cart.setDefaultPaymentMethod();
    this.save();
  }
  setBookingInfo(paymentUrl: string, bookingId: string, qr: string | null) {
    this.cart.bookingId = bookingId;
    this.cart.paymentUrl = paymentUrl;
    this.save();
  }
  togglePushkinMode(mode: number) {
    this.cart.mode = mode === 1 ? 0 : 1;

    if (this.cart.mode === 0) {
      this.cart.contacts?.controls['payment'].setValue(
        this.cart.getDefaultPaymentMethod()
      );
    } else {
      this.cart.contacts?.controls['payment'].setValue('PUSHKIN');
      if (this.cart.tickets.length > 1) {
        this.cart.clearTicketsForPushkin();
      }
    }
    const form = this.cart.contacts;
    this.save();
  }
  public clear(): void {
    this.cart = new ShoppingCart(this.cookie, this.storage);
  }
  addTicket(place: HallPlace, level: HallLevel) {
    // if (this.kiosk() === true && this.cart.tickets.length >= this.maxTickets) {
    //   this.showNotification.set(true);
    //   return;
    // }
    // if (place.status === 1) {
    //   this.cart.setTicket(place, level);
    //   this.save();
    // }
    const isPlaceSelected = this.cart.placeIsSelected(place);

    if (this.kiosk() === true && this.cart.tickets.length >= this.maxTickets && !isPlaceSelected) {
      this.showNotification.set(true);
      return;
    }

    if (place.status === 1) {
      if (isPlaceSelected) {
        const ticketToRemove = this.cart.tickets.find(ticket => ticket.id === place.id);
        if (ticketToRemove) {
          this.removeTicket(ticketToRemove);
        }
      } else {
        this.cart.setTicket(place, level);
      }
      this.save();
    }
  }
  removeTicket(ticket: Ticket) {
    this.cart.removeTicket(ticket);
    this.save();
  }
  private save(): void {
    const cart = { ...this.cart };
    delete cart['contacts'];
    delete cart['storage'];
    if (typeof cart['cookie'] !== 'undefined') delete cart['cookie'];
    if (typeof cart['storage'] !== 'undefined') delete cart['storage'];

    cart.createdAt = cart.createdAt.toLocaleString();
    cart.updatedAt = cart.updatedAt.toLocaleString();
    this.storage.setItem(CART_KEY, JSON.stringify(cart));
    this.dispatch();
  }
  private dispatch(initial = false) {
    const cart = this.cart;
    if (initial) {
      this.cart$ = new BehaviorSubject<ShoppingCart>(cart);
    } else {
      this.cart$.next(cart);
    }
  }
  public ready() {
    this.ready$.next(true);
  }
  public notReady() {
    this.ready$.next(false);
  }
}
