import { InjectionToken } from '@angular/core';
import { EnvironmentConfigResolved } from '../models/app.model';
import { environment } from 'src/environments/environment';
import { APP_VERSION } from 'src/environments/version';

// Dynamically extend environment with version if not already provided
export const ENVIRONMENT: EnvironmentConfigResolved = {
  ...environment,
  version: environment.version ?? APP_VERSION
};

// Injection token for EnvironmentConfig
export const ENVIRONMENT_CONFIG = new InjectionToken<EnvironmentConfigResolved>('EnvironmentConfig', {
  providedIn: 'root',
  factory: () => ENVIRONMENT
});
