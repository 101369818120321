<ng-container *ngIf="showScreensaver()===false;else save">
    <div *ngIf="remainingTime()>0" class="dialog">
        <div class="countdown-circle">
            <div class="countdown-sec active" *ngFor="let item of blocks;let i=index" [ngStyle]="calculateStyle(i)">
            </div>
            <div class="timer">
                <span class="count">00</span>
                <span class="divider">:</span>
                <span class="count">{{ remainingTime()<10?'0'+remainingTime():remainingTime() }}</span>
            </div>
        </div>
        <p class="question">Вы еще тут?</p>
        <div class="button-wrapper">
            <a cstc-button="secondary" class="secondary-btn" (click)="redirectToHome($event)">Выйти</a>
            <a cstc-button="primary" class="primary-btn" (click)="resetIdleTimer($event)">Продолжить</a>
        </div>
    </div>
</ng-container>
<ng-template #save>
    <div class="screensaver" (click)="redirectToHome($event)">
        <img class="logo" src="assets/img/logo.png" alt="">
        <p class="action">Нажмите на экран,<br>чтобы перейти к заказу</p>
        <!-- <cstc-kinozoom-logo neonPulse/> -->
        <img class="bg" src="assets/img/placeholders/screensaver.jpg" alt="screensaver">
    </div>
</ng-template>
