import { HttpClient } from '@angular/common/http';
import { effect, inject, Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, delay, distinctUntilChanged, filter, map, pluck, switchMap, takeUntil, takeWhile, tap } from 'rxjs';
import { FormatDirectoryItem, GenreDirectoryItem, DirectoryResponseContent, DirectoryResponseData, BannerDirectoryItem } from './data.model';
import { CityService } from 'src/app/services/city/city.service';
import { Film, Theatre } from '../schedule/schedule.model';
import { cachedRequest } from 'src/app/decorators/cache.decorator';
import { PlatformService } from '../platform/platform.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { ENVIRONMENT_CONFIG } from 'src/app/tokens/environment-config.token';

const compareFn = (a: Film, b: Film) => {
  if (a.ordering > b.ordering)
    return -1;
  if (a.ordering < b.ordering)
    return 1;
  return 0;
};

@Injectable({
  providedIn: 'root'
})
export class DataService implements OnDestroy {
  private appConfig = inject(ENVIRONMENT_CONFIG);
  private baseUrl = this.appConfig.baseUrl;
  stop$ = new Subject<void>();
  alive = true;
  loading$ = new BehaviorSubject(true);
  error$ = new BehaviorSubject(false);
  films$ = new BehaviorSubject<Film[] | undefined>(undefined);
  theatres$ = new BehaviorSubject<Theatre[] | undefined>(undefined);
  format$ = new BehaviorSubject<FormatDirectoryItem[] | undefined>(undefined);
  genre$ = new BehaviorSubject<GenreDirectoryItem[] | undefined>(undefined);
  top$ = new BehaviorSubject<BannerDirectoryItem[] | undefined>(undefined);
  bottom$ = new BehaviorSubject<BannerDirectoryItem[] | undefined>(undefined);
  loading = toSignal(this.loading$);

  constructor(
    private httpClient: HttpClient,
    private cityService: CityService,
    private platformSvc: PlatformService
  ) {
    this.cityService.filteredCity$.pipe(
      takeUntil(this.stop$),
      distinctUntilChanged(),
      tap(() => {
        this.loading$.next(true);
      }),
      switchMap((cityId: number) => this.load(cityId)),
      pluck('data'),
      delay(1000)
    ).subscribe((result: DirectoryResponseContent | undefined) => {
      this.loading$.next(false);
      if (result) {
        this.error$.next(false);
        this.format$.next((result.format) ? result.format : undefined);
        this.genre$.next((result.genre) ? result.genre : undefined);
        this.setMovies(result.movie);
        this.theatres$.next((result.theatre) ? result.theatre : undefined);
        this.top$.next((result.top) ? result.top : undefined);
        this.bottom$.next((result.bottom) ? result.bottom : undefined);
        if (this.platformSvc.platform() === 'ios') {
          this.platformSvc.setLatestVersionFromServer(result.ios, result.iosUrl);
        }
        if (this.platformSvc.platform() === 'android') {
          this.platformSvc.setLatestVersionFromServer(result.android, result.androidUrl);
        }
      } else {
        this.error$.next(true);
      }
    });
  }
  /**
   * Sets and processes the movies list:
   * 1. Filters movies by 'sale' if provided in appConfig.filters.
   * 2. Sorts movies using provided compare function.
   * 3. Pushes the final list to films$ observable.
   *
   * @param movies List of movies to set or `false` if no movies available.
   */
  setMovies(movies: Film[] | false): void {
    const movieFilters = this.appConfig.filters;

    // Step 1: Safely handle 'movies' input — ensure it's an array or empty array if false
    const moviesArray: Film[] = Array.isArray(movies) ? movies : [];

    // Step 2: Apply filtering by 'sale' if filter exists and is non-empty
    let filteredMovies = moviesArray;
    if (movieFilters?.sale && Array.isArray(movieFilters.sale) && movieFilters.sale.length > 0) {
      filteredMovies = moviesArray.filter(movie =>
        typeof movie.sale === 'number' && movieFilters.sale.includes(movie.sale)
      );
    }

    // Step 3: Sort filtered movies using compareFn (make sure compareFn is defined and imported)
    const sortedMovies = filteredMovies.sort(compareFn);

    // Step 4: Push result to observable — emit undefined if no movies, or array otherwise
    this.films$.next(sortedMovies.length > 0 ? sortedMovies : undefined);
  }

  @cachedRequest()
  load(cityId: number) {
    return this.httpClient.get<DirectoryResponseData>(
      `${this.baseUrl}/data/${cityId}`,
    );
  }
  ngOnDestroy(): void {
    this.alive = false;
    this.stop$.next();
    this.stop$.complete();
  }
  getProperty<T, K extends keyof T>(obj: T, key: K) {
    return obj[key]; // Inferred type is T[K]
  }
  getFilteredFilms(selectionFilter: any) {
    return this.films$.pipe(
      map(data => {
        if (!data) {
          return [];
        }
        if (typeof selectionFilter !== 'undefined' && selectionFilter) {
          const filtered = data.filter((item) => {
            for (const key in selectionFilter) {
              const a = key as keyof Film;
              if (a === 'dates' || a === 'formats') {
                const value = this.getProperty(item, a);
                return (Array.isArray(value) && value.length > 0) ? selectionFilter[key] : !selectionFilter[key];
              } else {
                if (this.getProperty(item, a) === undefined)
                  return false;
                if (selectionFilter[key] instanceof Array) {
                  if (!selectionFilter[key].includes(this.getProperty(item, a)))
                    return false
                } else {
                  if (this.getProperty(item, a) !== selectionFilter[key]) {
                    return false;
                  }
                }
              }
            }
            return true;
          });
          return filtered;
        }
        return data;
      })
    );
  }
  /**
   * Use this method in components to change value of loading signal
   * loading = toSignal(this.loading$); -  readonly
   *
   * Example:
   * loading = this.dataSvc.loading; - we cann't change it in component by using as loading.set(true)
   * we should use dataSvc.setLoading(true)
   */
  setLoading(value: boolean) {
    this.loading$.next(value);
  }
}
