import { HttpClient } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { AppPlatform, AppStoreType, PLATFORM_ANDROID, PLATFORM_IOS, PLATFORM_WEB, StreamConfig } from 'src/app/models/app.model';
import { environment } from 'src/environments/environment';
import { CstcStore } from 'src/app/store/state.store';
import { ENVIRONMENT_CONFIG } from 'src/app/tokens/environment-config.token';
declare const cordova: any;

export type AppMode = 'kiosk' | 'application';


export interface ServerResponse<T> {
  success: boolean;
  status: number;
  data: T;
}

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private appConfig = inject(ENVIRONMENT_CONFIG);
  http = inject(HttpClient);
  state = inject(CstcStore);
  // isKiosk = signal<boolean>(this.appConfig.kiosk);
  // Сигнал для хранения текущей платформы
  platform = signal<AppPlatform>(this.appConfig.platform);
  versionCurrent = signal<string>(this.appConfig.version);
  versionLatest = signal<string>(this.appConfig.version);
  storeVersion = signal<AppStoreType>(this.appConfig.store);
  updateUrl = signal<string>('');

  config: StreamConfig | null = null;
  streamId = computed(() => {
    const isStandalone = this.state.isStandalone();
    const isWideScreen = this.state.isWideScreen();
    const envStreamConfig = environment.stream;
    if (!envStreamConfig) {
      console.error('Stream configuration is missing in the environment.');
      return null;
    }

    // Function to get the stream number
    const getStreamNumber = (
      config: StreamConfig,
      isStandalone: boolean,
      isWideScreen: boolean
    ): number | null => {
      // Safely select the standalone configuration if applicable
      const selectedConfig = isStandalone && config.standalone ? config.standalone : config;

      if (!selectedConfig) {
        console.error('Standalone configuration is required but missing.');
        return null;
      }
      // Type guard for StreamConfig structure
      const hasDesktopAndMobile = (
        obj: Partial<StreamConfig>
      ): obj is { desktop?: number; mobile?: number } => {
        return 'desktop' in obj || 'mobile' in obj;
      };
      // Type guard for default property
      const hasDefault = (obj: Partial<StreamConfig>): obj is { default: number } => {
        return 'default' in obj && obj.default !== undefined;
      };
      if (hasDesktopAndMobile(selectedConfig)) {
        if (isWideScreen && selectedConfig.desktop !== undefined) {
          return selectedConfig.desktop;
        }

        if (!isWideScreen && selectedConfig.mobile !== undefined) {
          return selectedConfig.mobile;
        }
      }

      // Fallback to default if available
      if (hasDefault(selectedConfig)) {
        return selectedConfig.default;
      }

      console.error('Invalid configuration: No suitable stream found.');
      return null;
    };

    return getStreamNumber(envStreamConfig, isStandalone || false, isWideScreen);
  });


  updateAvailable = computed(() => {
    const currentVersion = this.versionCurrent();
    const latestVersion = this.versionLatest();

    if (!currentVersion || !latestVersion) {
      return false;
    }

    const currentParts = currentVersion.split('.');
    const latestParts = latestVersion.split('.');

    for (let i = 0; i < Math.max(currentParts.length, latestParts.length); i++) {
      const currentPart = parseInt(currentParts[i] || '0');
      const latestPart = parseInt(latestParts[i] || '0');

      if (currentPart < latestPart) {
        return true;
      } else if (currentPart > latestPart) {
        return false;
      }
    }
    return false;
  });
  showMobileAppMenu = computed(() => {
    const platform = this.platform();
    const isStandalone = this.state.isStandalone() && this.state.isWideScreen() === false;
    const isMobileApp = this.isMobileApp();
    return isMobileApp || (platform === PLATFORM_WEB && isStandalone);
  });
  isMobileApp = computed(() => {
    const platform = this.platform();
    return [PLATFORM_ANDROID, PLATFORM_IOS].includes(platform);
  })
  setLatestVersionFromServer(version: string, url: string) {
    this.versionLatest.set(version);
    this.updateUrl.set(url);
  }

  // Метод для проверки мобильного устройства
  isMobileDevice() {
    return (
      /Mobi|Android|iPhone|iPad|iPod|Opera Mini|IEMobile|Mobile/i.test(navigator.userAgent) ||
      window.navigator.maxTouchPoints > 1 || screen.width < 1024
    );
  }
}
